import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class ConsultaService {
  constructor(private http: HttpClient) { }

  getSubrede(): Observable<any> {
    return this.http.get("consultas/getempresa");
  }

  getClientesSubRede(idsubrede: any, idcliente: any = ""): Observable<any> {
    return this.http.get("consultas/getclientesubrede", {
      params: {
        idsubrede: idsubrede,
        idcliente: idcliente,
      },
    });
  }

  getConsultaCredenciados(parametros: any): Observable<any> {
    return this.http.get("consultas/getconsultacredenciados", {
      params: parametros,
    });
  }

  getCartaCircularizacaoCred(parametros: any): Observable<any> {
    return this.http.get("consultas/cartaCircularizacao", {
      params: parametros,
    });
  }

  getConsultaLogAjusteOdometro(parametros: any = ""): Observable<any> {
    return this.http.get("consultas/getconsultalogajusteodometro", {
      params: { parametros },
    });
  }

  getConsultaCredenciadosInterno(parametros: any = ""): Observable<any> {
    if (parametros != "") {
      return this.http.get("consultas/getconsultacredenciadosinterno", {
        params: parametros,
      });
    }

    return this.http.get("consultas/getconsultacredenciados");
  }

  getConsultaCredenciadosGood(parametros: any): Observable<any> {
    return this.http.get("consultas/getconsultacredenciadosgood", {
      params: parametros,
    });
  }

  getConsultaCredenciadosCompleto(parametros: any = ""): Observable<any> {
    if (parametros != "") {
      return this.http.get("consultas/getconsultacredenciadoscompleto", {
        params: { parametros },
      });
    }

    return this.http.get("consultas/getconsultacredenciados");
  }

  getConsultaClientesInterno(parametros: any = ""): Observable<any> {
    if (parametros != "") {
      return this.http.get("consultas/getconsultaclientesinterno", {
        params: { parametros },
      });
    }

    return this.http.get("consultas/getconsultaclientes");
  }

  getConsultaCredencialMovimentacao(parametros: any): Observable<any> {
    return this.http.get("consultasadmin/consulta-credenciado-movimentacao", {
      params: { parametros },
    });
  }

  getConsultaConsumoCombustivel(parametros: any): Observable<any> {
    return this.http.get("consultas/gerarconsulconsumocombustivel", {
      params: { parametros },
    });
  }

  getConsultaConsumoVeiculos(parametros: any): Observable<any> {
    return this.http.get("consultas/gerarconsulconsumoveiculo", {
      params: { parametros },
    });
  }

  prepararConsulta(idcliente: any = ""): Observable<any> {
    return this.http.get("consultas/prepararconsulta", {
      params: { idcliente },
    });
  }

  getModelosMotoristaCentroCusto(
    idcentrocusto: any = "",
    idcliente: any = ""
  ): Observable<any> {
    return this.http.get("consultas/getmodelosmotoristascentrocusto", {
      params: { idcentrocusto, idcliente },
    });
  }

  getModeloVeiculoClientePorTipoVeiculo(
    idTipoVeiculo: string,
    idCliente: number
  ): Observable<any> {
    return this.http.get(
      "consultas/pesquisarModeloVeiculoClientePorTipoVeiculo",
      {
        params: { idTipoVeiculo, idCliente },
      }
    );
  }

  getConsultaRegraVeiculo(parametros: any): Observable<any> {
    return this.http.get("consultas/getconsultaregraveiculo", {
      params: { parametros },
    });
  }

  getTransacoesCredenciadoDesconto(parametros: any): Observable<any> {
    return this.http.get("consultas/relatoriodescontocredenciadocliente", {
      params: { parametros },
    });
  }

  pesquisarManutencaoOs(parametros: any): Observable<any> {
    return this.http.get("consultas/pesquisarmanutencaoos", {
      params: { parametros },
    });
  }

  pesquisarOperacaoManutencao(parametros: any): Observable<any> {
    return this.http.get("consultas/operacaomanutencao", {
      params: { parametros },
    });
  }

  pesquisarPrevisaoTroca(parametros: any): Observable<any> {
    return this.http.get("consultas/previsaotroca", {
      params: { parametros },
    });
  }

  pesquisarBiDesempenhoModelo(parametros: any): Observable<any> {
    return this.http.get("consultas/pesquisarbidesempenhomodelo", {
      params: { parametros },
    });
  }

  pesquisarBiDesempenhoMotorista(parametros: any): Observable<any> {
    return this.http.get("consultas/pesquisarbidesempenhomotorista", {
      params: { parametros },
    });
  }

  pesquisarBiSaldoLimite(parametros: any): Observable<any> {
    return this.http.get("consultas/pesquisarbisaldolimite", {
      params: { parametros },
    });
  }

  pesquisarConsumoEntreVeiculos(parametros: any): Observable<any> {
    return this.http.get("consultas/consumocombustivelentreveiculos", {
      params: { parametros },
    });
  }

  gerarConsultaGastosVeiculosCred(parametros): Observable<any> {
    return this.http.get("consultas/pesquisaconsultagastosveiculoscred", {
      params: parametros,
    });
  }

  gerarConsultaGastosVeiculosCredAnalitico(parametros): Observable<any> {
    return this.http.get(
      "consultas/pesquisaconsultagastosveiculoscredanalitico",
      {
        params: parametros,
      }
    );
  }

  gerarConsultaGastosVeiculosCredSimplificado(parametros): Observable<any> {
    return this.http.get(
      "consultas/pesquisaconsultagastosveiculoscredsimplificado",
      {
        params: parametros,
      }
    );
  }

  gerarConsultaBiGastosMotoristaCred(parametros: any): Observable<any> {
    return this.http.get("consultas/gerarconsultabigastosmotoristacred", {
      params: { parametros },
    });
  }

  gerarConsultaBiPrecoPraticaRede(parametros: any): Observable<any> {
    return this.http.get("consultas/gerarconsultabiprecopraticarede", {
      params: { parametros },
    });
  }

  getConsultaIndeseTrans(parametros: any): Observable<any> {
    return this.http.get("consultas/gerarconsultaindesetrans", {
      params: { parametros },
    });
  }

  getConsultaTransacaoPorNotaRecolhida(parametros: any): Observable<any> {
    return this.http.get("consultas/gerarconsultatransacaonotarecolhida", {
      params: parametros,
    });
  }

  getConsultaTransacaoPorVeiculo(parametros: any): Observable<any> {
    return this.http.get("consultas/gerarconsultatransacaoveiculo", {
      params: { parametros },
    });
  }

  getConsultaTransacaoPorVeiculoNovo(parametros: any): Observable<any> {
    return this.http.get("consultas/gerarconsultatransacaoveiculoCentroCusto", {
      params: { parametros },
    });
  }

  getFaturamentoRetencao(parametros: any): Observable<any> {
    return this.http.get("consultas/faturamentoretencao", {
      params: { parametros },
    });
  }

  getConsultaAvisoTrocaOleo(parametros: any): Observable<any> {
    return this.http.get("consultas/gerarconsultatrocaoleo", {
      params: { parametros },
    });
  }

  gerarConsultaBiCustoKM(parametros: any): Observable<any> {
    return this.http.get("consultas/gerarconsultabicustokm", {
      params: { parametros },
    });
  }

  gerarConsultaBiPesquisaPorVeiculos(parametros: any): Observable<any> {
    return this.http.get("consultas/gerarconsultabipesquisaporveiculos", {
      params: { parametros },
    });
  }

  enviarHtmlPorEmail(dados: any): Observable<any> {
    return this.http.post("consultas/enviarhtmlporemail", {
      parametros: dados,
    });
  }

  relatorioCadastroVeiculo(parametros: any): Observable<any> {
    return this.http.get("relatorio/relatoriocadastroveiculo", {
      params: { parametros },
    });
  }

  relatorioLiberacaoTransacao(parametros: any): Observable<any> {
    return this.http.get("consultas/logsLiberacoes", {
      params: parametros,
    });
  }

  relatorioCadastroVeiculoGerencial(parametros: any): Observable<any> {
    return this.http.get("relatorio/relatoriocadastroveiculogerencial", {
      params: { parametros },
    });
  }

  relatorioCadastroCartao(parametros: any): Observable<any> {
    return this.http.get("relatorio/relatoriocadastrocartao", {
      params: { parametros },
    });
  }

  getConferenciaNfConsolidacao(parametros: any): Observable<any> {
    return this.http.get("consultas/getconferencianfconsolidacao", {
      params: parametros,
    });
  }

  relatorioCadastroCargas(parametros: any): Observable<any> {
    return this.http.get("relatorio/relatoriocadastrocargas", {
      params: { parametros },
    });
  }

  relatorioCadastroMotorista(parametros: any): Observable<any> {
    return this.http.get("relatorio/relatoriocadastromotorista", {
      params: { parametros },
    });
  }

  consultaKmAtual(parametros: any): Observable<any> {
    return this.http.get("consultas/geraconsultakmatual", {
      params: { parametros },
    });
  }

  pesquisarTagVinculada(parametros: any): Observable<any> {
    return this.http.get("consultas/pesquisartagvinculada", {
      params: { parametros },
    });
  }

  pesquisarTagEstacionamentoAtivo(parametros: any): Observable<any> {
    return this.http.get("consultas/pesquisartagestacionamentoativo", {
      params: { parametros },
    });
  }

  pesquisarTagCancelada(parametros: any): Observable<any> {
    return this.http.get("consultas/pesquisartagcancelada", {
      params: { parametros },
    });
  }

  pesquisarBiLimiteExtra(parametros: any): Observable<any> {
    return this.http.get("consultas/pesquisarbilimiteextra", {
      params: { parametros },
    });
  }

  getConsultaTransacoesGood(parametros: any): Observable<any> {
    return this.http.get("consultas/gerarconsultatransacoesgood", {
      params: { parametros },
    });
  }

  getConsultaTransacoesConciliadasGood(parametros: any): Observable<any> {
    return this.http.get("consultas/gerarconsultatransacoesconciliadasgood", {
      params: parametros,
    });
  }

  getSolicitacoesOrcamentoReport(filtros: any): Observable<any> {
    return this.http.get("relatorio/solicitacaoOrcamento", {
      params: filtros,
    });
  }

  getOsRealizadasCredReport(filtros: any): Observable<any> {
    return this.http.get("relatorio/credenciadoos", {
      params: filtros,
    });
  }

  getOsRealizadasCredAnaliticoReport(filtros: any): Observable<any> {
    return this.http.get("relatorio/credenciadoosdetalhes", {
      params: filtros,
    });
  }

  getQtdOsRealizadasCredReport(filtros: any): Observable<any> {
    return this.http.get("relatorio/credenciadoostipo", {
      params: filtros,
    });
  }

  getGastosPecasServicosVeiculosQuilometro(filtros: any): Observable<any> {
    return this.http.get("relatorio/centrocustoveiculo", {
      params: filtros,
    });
  }

  getGastosPecasServicosPorVeiculos(filtros: any): Observable<any> {
    return this.http.get("relatorio/gastoPecasVeiculo", {
      params: filtros,
    });
  }

  getTransacoesCredenciados(filtros: any): Observable<any> {
    return this.http.get("consultas/transacoesCredenciados", {
      params: filtros,
    });
  }
}
